<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<DataTable ref="dt" :value="purchases" v-model:selection="selectedPurchase" selectionMode="single" @rowSelect="onRowSelected" dataKey="id" :paginator="true" :rows="20" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,35,50]"
							currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} egresos" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Egresos</h5>
							<div id="table-header-inputs-container">
								<span id="table-search-input" class="block mt-2 md:mt-0 p-input-icon-left">
									<i class="pi pi-search" />
									<AutoComplete placeholder="Buscar por nombre..." ref="autocomplete" :dropdown="false" :multiple="false" v-model="filters['global'].value" @complete="onSearch($event)"></AutoComplete>
								</span>
								<span id="table-buttons-container" class="block mt-2 md:mt-0">
									<Button icon="pi pi-plus" class="p-button-rounded p-button-success mr-2" @click="openNew" />
									<Button icon="pi pi-filter" class="p-button-rounded p-button-secondary mr-2" @click="toggleFilterMenu($event)" />
									<OverlayPanel ref="op2" appendTo="body" :showCloseIcon="false" id="overlay_panel2" style="width: 250px">
										<h5>Nombre o descripción</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<InputText v-model="this.clientFilter" id="client" type="text" placeholder="Nombre o descripción..." style="width: 100%;"/>
											</div>
										</div>
										<h5>Estado de pago</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Pagada</label>
													<RadioButton id="option1" name="option" value="paid" v-model="paidFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Por pagar</label>
													<RadioButton id="option2" name="option" value="pending" v-model="paidFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option3" style="width: 100%">Vencidas</label>
													<RadioButton id="option3" name="option" value="expired" v-model="paidFilter" />
												</div>
											</div>
										</div>
										<h5 v-show="this.business.makesRetention">Retenciones</h5>
										<div v-show="this.business.makesRetention" class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Emitidas</label>
													<RadioButton id="option1" name="option" value="authorized" v-model="edocFilter" />
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Anuladas</label>
													<RadioButton id="option2" name="option" value="nulled" v-model="edocFilter" />
												</div>
											</div>
										</div>
										<h5>Rango de fechas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Inicio</label>
													<Calendar :showIcon="true" v-model="startFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Fin</label>
													<Calendar :showIcon="true" v-model="endFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
										</div>
										<h5>Cantidad registros</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<InputText v-model="this.amountFilter" id="amount" type="number" placeholder="Cantidad registros..." style="width: 100%;"/>
											</div>
										</div>
										<Button class="p-button-success mr-2" style="width: 100%;  margin-top: 15px;" :loading="this.loadingFilterButton" label="Filtrar" @click="filterSales" />
									</OverlayPanel>
									<Button icon="pi pi-download" class="p-button-rounded p-button-help mr-2" @click="toggleDownloadMenu($event)" />
									<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 250px">
										<h5>Formato de descarga</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option1" style="width: 100%">Excel</label>
													<RadioButton id="option1" name="option" value="excel" v-model="formatOption"/>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0">
													<label for="option2" style="width: 100%">Zip</label>
													<RadioButton id="option2" name="option" value="zip" v-model="formatOption"/>
												</div>
											</div>
										</div>
										<h5>Rango de fechas</h5>
										<div class="grid" style="display: block;">
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Inicio</label>
													<Calendar :showIcon="true" v-model="initialFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
											<div class="col-12 md:col-12">
												<div class="field-radiobutton mb-0" style="display: block;">
													<label>Fin</label>
													<Calendar :showIcon="true" v-model="finalFilterDate" dateFormat="dd/mm/yy"></Calendar>
												</div>
											</div>
										</div>
										<Button class="p-button-success mr-2" style="width: 100%;  margin-top: 15px;" :loading="this.loadingDownloadButton" label="Descargar" @click="downloadPurchaseReport" />
									</OverlayPanel>
								</span>
							</div>
							
						</div>
					</template>

                    <Column field="serial" header="Serial" :sortable="true" headerStyle="width:10%; min-width:8rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Serial</span>
							{{ slotProps.data.serial }}
                        </template>
                    </Column>
                    <Column field="title" header="Razón Social / Descripción" :sortable="true" headerStyle="width:30%; min-width:10rem;">
                        <template #body="slotProps">
                            <span class="p-column-title">Razón Social / Descripción</span>
                            {{ slotProps.data.title }}
                        </template>
                    </Column>
					<Column field="total" header="Total" :sortable="true" headerStyle="width:20%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Total</span>
							{{"$"+slotProps.data.total.toFixed(2)}}
						</template>
					</Column>
                    <Column field="createdAtFormatted" header="Fecha" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.createdAtFormatted}}
						</template>
					</Column>
					<Column field="edocStatus" header="Estado Documento" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span :class="'status-badge status-' + slotProps.data.edocStateBadgeColor">{{slotProps.data.edocState}}</span>
						</template>
					</Column>
					<Column field="collectStatusReadable" header="Estado Pago" :sortable="true" headerStyle="width:25%; min-width:10rem;">
						<template #body="slotProps">
							<span :class="'status-badge status-' + slotProps.data.collectStatusBadgeColor">{{slotProps.data.collectStatusReadable}}</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
import download from 'downloadjs';
import {FilterMatchMode} from 'primevue/api';
import httpRequest from '../../service/httpRequest';
import Purchase from '../../models/purchase';
import Business from '../../models/business';

export default {
	data() {
		return {
			business: new Business(),
			purchases: [],
            purchase: new Purchase(),
            selectedPurchase: null,
			filters: {},
			formatOption: 'excel',
			saleType: 'all',
			initialFilterDate: null,
			finalFilterDate: null,
			loadingDownloadButton: false,

			// Filters
			clientFilter: null,
			paidFilter: null,
			edocFilter: null,
			startFilterDate: null,
			endFilterDate: null,
			amountFilter: null,

			// Loading
			loadingFilterButton: false,
		}
	},
	created() {
        this.initFilters();
        this.getPurchases();
	},
	async beforeMount() {
		this.business = await this.$store.getters.business;
	},
	methods: {
		openNew() {
			this.$router.push('form');
		},
        async getPurchases() {
            let response = await httpRequest.getPurchases();
            if (response.status == 200) {
                let purchases = response.data;
                this.purchases = purchases.map(function(data) { return new Purchase(data)});
            }
        },
		toggleFilterMenu(event) {
			this.$refs.op2.toggle(event);
		},
		toggleDownloadMenu(event) {
			this.$refs.op1.toggle(event);
		},
		confirmDeleteSelected() {
			this.deleteSalesDialog = true;
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        },
        saleIndex(sale) {
            var index = -1;
            if (sale.id === null) { index = -1; return index; }
            for (var i=0; i<this.sales.length; i++) {
                if (this.sales[i].id === sale.id) { index = i; break }
            }
            return index;
        },
        addSaleToList(sale) {
            let index = this.saleIndex(sale);
            if (index > -1) { this.sales[index] = sale }
            else { this.sales = [sale, ...this.sales] } 
        },
        deleteSaleFromList(sale) {
            let index = this.saleIndex(sale);
            this.sales.splice(index, 1);
            this.sale = null;
            if (this.sales.length == 0) { this.sales = [] }
        },
		onRowSelected(event) {
			let object = event.data;
			this.$router.push('form?id=' + object.id);
		},
		async onSearch(event) {
			let response = await httpRequest.getPurchases(event.query);
            if (response.status == 200) {
                let purchases_ = response.data;
				for (var index in purchases_) {
					if (!this.purchases.some(p => (p.id == purchases_[index].id))) {
						this.purchases.push(new Purchase(purchases_[index]));
					}
				}
            }
		},
		async filterSales() {
			// Make date verification
			if (this.startFilterDate !== null && this.endFilterDate !== null && this.startFilterDate>this.endFilterDate) {
				this.$toast.add({severity:'error', summary: 'Fechas incorrectas', detail: 'La fecha inicial no puede ser mayor a la fecha final', life: 3000});
				return;
			}

			let start = this.startFilterDate !== null ? this.startFilterDate.toISOString() : null
			let end = this.endFilterDate !== null ? this.endFilterDate.toISOString() : null
			this.loadingFilterButton = true;

			let response = await httpRequest.getPurchases(this.clientFilter, start, end, this.paidFilter, this.edocFilter, this.amountFilter);
            if (response.status == 200) {
                let purchases_ = response.data;
                this.purchases = purchases_.map(function(data) { return new Purchase(data)});
            } else { this.purchases = []; }
			this.loadingFilterButton = false;
			this.$refs.op2.toggle(false);
		},
		async downloadPurchaseReport() {
			// Make date verification
			if (this.initialFilterDate !== null && this.finalFilterDate !== null && this.initialFilterDate>this.finalFilterDate) {
				this.$toast.add({severity:'error', summary: 'Fechas incorrectas', detail: 'La fecha inicial no puede ser mayor a la fecha final', life: 3000});
				return;
			}

			// Parse dates
			let initialDate = this.initialFilterDate !== null ? this.initialFilterDate.toISOString() : null
			let endDate = this.finalFilterDate !== null ? this.finalFilterDate.toISOString() : null

			this.loadingDownloadButton = true;
			
			const data = await httpRequest.downloadPurchaseReport(this.formatOption, initialDate, endDate);
			const filename = (this.formatOption === "excel") ? "egresos.xlsx" : "retenciones.zip"
			const extension = (this.formatOption === "excel") ? "vnd.ms-excel" : "zip"
			download(data, filename, "application/" + extension);

			this.loadingDownloadButton = false;
		},
	}
}
</script>

<style scoped lang="scss">
@import '../../assets/demo/badges.scss';

@media screen and (max-width: 769px) {
	#table-header-inputs-container {
		display: block;
	}
	#table-search-input {
		margin: 15px 0px !important;
	}
}
@media screen and (min-width: 769px) {
	#table-header-inputs-container {
		display: flex;
	}
	#table-search-input {
		margin: 0px 15px;
	}
}

.status-badge {
    border-radius: var(--border-radius);
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-badge {
    &.status-green {
        background: #C8E6C9;
        color: #256029;
    }

    &.status-red {
        background: #FFCDD2;
        color: #C63737;
    }

    &.status-yellow {
        background: #FEEDAF;
        color: #8A5340;
    }

    &.status-orange {
        background: #ffa177;
        color: #da2727;
    }

	&.status-blue {
        background: #B3E5FC;
        color: #23677B;
    }

	&.status-gray {
        background: #d6d8d9;
        color: #464747;
    }
}
</style>
