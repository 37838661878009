import PurchaseItem from "./purchaseItem";
import EDocument from "./edocument";
import store from '../store'

const Purchase = class {
    constructor(object) {
        this.rentPercentageOptions = [
            {name: '0%', value: '0.0'}, {name: '1%', value: '0.01'}, {name: '1.75%', value: '0.0175'},
            {name: '2%', value: '0.02'}, {name: '2.75%', value: '0.0275'}, {name: '8%', value: '0.08'}, {name: '10%', value: '0.1'},
        ]

        this.ivaPercentageOptions = [
            {name: '0%', value: '0.0'},
            {name: '10%', value: '0.1'}, {name: '20%', value: '0.2'}, {name: '30%', value: '0.3'},
            {name: '50%', value: '0.5'}, {name: '70%', value: '0.7'}, {name: '100%', value: '1'},
        ]
        
        if (object === undefined) {
            this.id = null;
            this.vendorName = null;
            this.vendorIdentification = null;
            this.reason = null;
            this.serialNumber = null;
            this.status = null;
            this.sub0 = 0.0;
            this.sub12 = 0.0;
            this.tax = 0.0;
            this.taxPercentage = store.getters.business.taxPercentage;
            this.discount = 0.0;
            this.total = 0.0;
            this.createdAt = new Date();
            this.creditDays = 0;
            this.paymentMethod = {name: 'Efectivo', code: 'CASH'};
            this.items = [];
            this.ivaPercentage = null;
            this.rentPercentage = null;
            this.edoc = null;
            this.edocStatus = null;
        } else {
            this.id = object.id;
            this.vendorName = object.vendorName;
            this.vendorIdentification = object.vendorIdentification;
            this.reason = object.reason;
            this.serialNumber = object.serialNumber;
            this.status = object.status;
            this.sub0 = object.base0;
            this.sub12 = object.base12;
            this.tax = object.tax;
            this.taxPercentage = object.taxPercentage || store.getters.business.taxPercentage;
            this.discount = object.discount;
            this.total = object.total;
            this.createdAt = new Date(object.createdAt);
            this.creditDays = object.creditDays;
            this.paymentMethod = object.paymentMethod;
            this.edocStatus = object.edocStatus;
            
            this.rentPercentage = this.getRentPercentageOption(object.rentRetentionPercentage);
            this.ivaPercentage = this.getIVAPercentageOption(object.ivaRetentionPercentage);

            // Parse edoc
            if (object.edoc !== undefined && object.edoc !== null) {
                this.edoc = new EDocument(object.edoc);
            } else { this.edoc = null }

            // Parse items if exists
            if (object.items !== undefined && object.items !== null && object.items.length > 0) { 
                this.items = object.items.map(item => new PurchaseItem(item)); 
            } else { this.items = [] }
        }
    }

    get title() {
        return this.vendorName ? this.vendorName : this.reason
    }
    
    get serial() {
        return this.serialNumber ? this.serialNumber : this.id.toString().padStart(9, "0")
    }

    get subtotal() {
        const sub0 = parseFloat(this.sub0);
        const sub12 = parseFloat(this.sub12);
        return (sub0 + sub12).toFixed(2);
    }
    get createdAtFormatted() {
        var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
        return this.createdAt.toLocaleDateString('es-EC', options);
    }
    get dueDate() {
        if (this.creditDays > 0) {
            var dueDate = (this.createdAt !== null) ? new Date(this.createdAt) : new Date();
            dueDate.setDate(dueDate.getDate() + parseInt(this.creditDays));
            return dueDate;
        }
        return null;
    }
    get dueDateFormatted() {
        if (this.dueDate !== null) {
            var options = { year: 'numeric', month: 'numeric', day: 'numeric'};
            return this.dueDate.toLocaleDateString('es-EC', options);
        }
        return null;
    }
    get collectStatusReadable() {
        if (this.status === "PAID") { return "PAGADA"}
        if (this.status === "PENDING" && this.dueDate !== null && this.dueDate <= new Date()) { return "VENCIDA"}
        else { return "POR PAGAR" }
    }
    get collectStatusBadgeColor() {
        if (this.status === "PAID") { return "green"}
        if (this.status === "PENDING" && this.dueDate !== null && this.dueDate <= new Date()) { return "orange"}
        else { return "blue" }
    }

    get edocState() {
        if (this.edocStatus === "none") { return "NO APLICA" }
        else if (this.edocStatus === "nulled") { return "ANULADA" }
        else if (this.edocStatus === "authorized") { return "AUTORIZADO" }
        else if (this.edocStatus === "received") { return "POR AUTORIZAR" }
        else { return "POR REPORTAR" }
    }
    get edocStateBadgeColor() {
        if (this.editable === true) { return "blue" }
        else if (this.edocStatus === "none") { return "gray" }
        else if (this.edocStatus === "nulled") { return "red" }
        else if (this.edocStatus === "authorized") { return "green" }
        else if (this.edocStatus === "received") { return "yellow" }
        else { return "orange" }
    }

    get rentRetentionValue() {
        let rentPercentage = (this.rentPercentage !== null) ? parseFloat(this.rentPercentage.value) : 0.0
        return (rentPercentage * (parseFloat(this.sub0) + parseFloat(this.sub12))).toFixed(2);
    }

    get ivaRetentionValue() {
        let ivaPercentage = (this.ivaPercentage !== null) ? parseFloat(this.ivaPercentage.value) : 0.0
        return (ivaPercentage * parseFloat(this.tax)).toFixed(2);
    }

    get shouldCreateEDOC() {
        return !(this.id === null) &&
                !(this.vendorName === "" || this.vendorName === null) &&
                !(this.vendorIdentification === "" || this.vendorIdentification === null) &&
                !(this.rentPercentage === "" || this.rentPercentage === null) &&
                !(this.ivaPercentage === "" || this.ivaPercentage === null)
    }

    get reportedWithReceipt() {
        return (this.id !== null) && 
                (this.vendorName !== "" && this.vendorName !== null) && 
                (this.vendorIdentification !== "" && this.vendorIdentification !== null) &&
                (this.serialNumber !== null && this.serialNumber !== undefined)
    }

    addItem(item) {
        let index = this.getItemIndex(item);
        if (index === -1) { this.items.unshift(item) } 
        else { this.items[index] = item }
        this.updateValues();
     }
     deleteItem(item) {
        let index = this.getItemIndex(item);
        this.items.splice(index, 1);
        if (this.items.length == 0) { this.items = [] }
        this.updateValues();
    }
    getItemIndex(item) {
        var index = -1;
        if (item.productId === null || this.items === null || this.items === undefined) { return -1; }
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].productId === item.productId) { return i; }
        }
        return index;
    }
    updateValues() {
        var sub0_ = 0.0;
        var sub12_ = 0.0;
        var discount_ = 0.0;
        for (var i=0; i<this.items.length; i++) {
            if (this.items[i].hasTax) {
                sub12_ += this.items[i].totalPrice;
            } else {
                sub0_ += this.items[i].totalPrice;
            }
            discount_ += (this.items[i].amount * this.items[i].unitPrice * this.items[i].discount)
        }
        this.sub0 = sub0_;
        this.sub12 = sub12_;
        this.tax = sub12_ * store.getters.business.tax;
        this.discount = discount_;
        this.total = sub0_ + sub12_ + this.tax;
    }
    getRentPercentageOption(percentage) {
        for (var i=0; i<this.rentPercentageOptions.length; i++) {
            if (percentage==this.rentPercentageOptions[i].value) {
                return this.rentPercentageOptions[i];
            } 
        }
        return null;
    }
    getIVAPercentageOption(percentage) {
        for (var i=0; i<this.ivaPercentageOptions.length; i++) {
            if (percentage==this.ivaPercentageOptions[i].value) {
                return this.ivaPercentageOptions[i]; 
            } 
        }
        return null;
    }
}

export default Purchase