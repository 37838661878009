const PurchaseItem = class {
    constructor(object) {
        if (object === undefined) {
            this.id = null
            this.code = null
            this.productId = null
            this.product = null;
            this.hasTax = false;
            this.amount = 0.0;
            this.discount = 0.0;
            this.readableDiscount = 0.0;
            this.unitPrice = 0.0;
            this.totalPrice = 0.0;
        } else {
            this.id = object.id || null;
            this.code = object.code || null;
            this.productId = object.productId;
            this.product = object.product;
            this.hasTax = object.hasTax;
            this.amount = object.amount || 1;
            this.discount = object.discount;
            this.readableDiscount = this.discount * 100.0;
            this.unitPrice = object.unitPrice;
            this.totalPrice = this.unitPrice * this.amount * (1 - this.discount);
        }
        this.readableTax = this.hasTax ? "SI" : "NO";
    }
}

export default PurchaseItem